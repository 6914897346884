/**
 * Colorbox Core Style:
 * The following CSS is consistent between example themes and should not be altered.
 */
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}
#cboxContent {
  position: relative;
}
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
#cboxTitle {
  margin: 0;
}
#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  cursor: pointer;
}
/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */
#cboxPrevious:active, #cboxNext:active, #cboxClose:active, #cboxSlideshow:active {
  outline: 0;
}
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
}
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}
/* Reset box sizing to content-box if theme is using border-box. */
#colorbox, #cboxContent, #cboxLoadedContent {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/**
 * Colorbox module default style:
 * The styles are ordered & tabbed in a way that represents
 * the nesting of the generated HTML.
 */
#cboxOverlay {
  background: #000;
}
#colorbox {
  outline: 0;
}

#cboxContent {
  padding-top: 28px;
}
  #cboxWrapper {
    background: #fff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
    // #cboxTopLeft {
    //   width: 15px;
    //   height: 15px;
    // }
    // #cboxTopCenter {
    //   height: 15px;
    // }
    // #cboxTopRight {
    //   width: 15px;
    //   height: 15px;
    // }
    // #cboxBottomLeft {
    //   width: 15px;
    //   height: 10px;
    // }
    // #cboxBottomCenter {
    //   height: 10px;
    // }
    // #cboxBottomRight {
    //   width: 15px;
    //   height: 10px;
    // }
    // #cboxMiddleLeft {
    //   width: 15px;
    // }
    // #cboxMiddleRight {
    //   width: 15px;
    // }
    #cboxContent {
      background: #fff;
      overflow: hidden;
      padding-top: 16px;
    }
      #cboxError {
        padding: 50px;
        border: 1px solid #ccc;
      }
      #cboxLoadedContent {
        margin-bottom: 28px;
      }
      #cboxTitle {
        position: absolute;
        background: rgba(255, 255, 255, 0.7);
        bottom: 28px;
        left: 0;
        color: #535353;
        width: 100%;
        padding: 4px 6px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      #cboxCurrent {
        position: absolute;
        bottom: 4px;
        left: 60px;
        color: #949494;
      }
      .cboxSlideshow_on #cboxSlideshow {
        position: absolute;
        bottom: 0px;
        right: 30px;
        background: url(../images/controls.png) no-repeat -75px -50px;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }
      .cboxSlideshow_on #cboxSlideshow:hover {
        background-position: -101px -50px;
      }
      .cboxSlideshow_off #cboxSlideshow {
        position: absolute;
        bottom: 0px;
        right: 30px;
        background: url(../images/controls.png) no-repeat -25px -50px;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }
      .cboxSlideshow_off #cboxSlideshow:hover {
        background-position: -49px -50px;
      }
      #cboxPrevious {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url(../images/controls.png) no-repeat -75px 0px;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }
      #cboxPrevious:hover {
        background-position: -75px -25px;
      }
      #cboxNext {
        position: absolute;
        bottom: 0;
        left: 27px;
        background: url(../images/controls.png) no-repeat -50px 0px;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }
      #cboxNext:hover {
        background-position: -50px -25px;
      }
      #cboxLoadingOverlay {
        background: #fff;
      }
      #cboxLoadingGraphic {
        background: url(../images/loading_animation.gif) no-repeat center center;
      }
      #cboxClose {
        position: absolute;
        top: 0;
        right: 0;
        background: url(../images/controls.png) no-repeat -25px 0px;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }
      #cboxClose:hover {
        background-position: -25px -25px;
      }
